export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('checkout_cardnumber_data', () => ({
      async init() {
        if (this.stripeElements !== undefined) {
          this.initElement(this.stripeElements);
        }
      },

      mountStripe() {
        this.stripeElement?.mount(this.$el);
      },

      initElement(stripeElements) {
        this.stripeElement = stripeElements.create("cardNumber");
        this.mountStripe();
      },

      'checkout_cardnumber_bind': {
        '@stripe-initialized.window'() {
          const { stripeApi, stripeElements } = this.$event.detail;
        }
      }
    }))
  })
}