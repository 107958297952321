import type { GlobalScript } from "@/editor/ambient";

export const addProductsAlpine = (
  document: Document,
  window: Window,
  workspaceData: any,
  ProductApi: any
) => {
  let F;
  document.addEventListener('alpine:init', F = (() => {
    window.Alpine.store('products', {
      spaceId: '',
      init() {
        (this as any).spaceId = workspaceData.spaceId;
        (this as any).ProductApi = ProductApi;
        (this as any).publicStripeToken = workspaceData.stripePublicToken;
        console.log((this as any).publicStripeToken);
      },
    })
  }));

  return F;
}

declare global {
  interface PageAmbient {
    addProductsAlpine: (workspaceData: any, productApi: any) => void
  }
}

const InjectAddProducts: GlobalScript = (window, document) => {
  window.addProductsAlpine = (workspaceData, productApi) => 
    addProductsAlpine(
      document, window,
      workspaceData,
      productApi
    );
}
InjectAddProducts.priority = -1;

export default InjectAddProducts;