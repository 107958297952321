import type { GlobalScript } from "@/editor/ambient"
const InjectHandleTemplates: GlobalScript = (window, document) => {
  window.handleAlpineTemplates = () => {
    // This very much is a workaround, this should be handled more gracefully
    // Initiate the templates, then proceed business as usual.
    document.querySelectorAll("[x-for-tmp]").forEach((el) => {
      const template = document.createElement("template");
      const xFor = el.getAttribute("x-for-tmp");
      const parent = el.parentElement;
      el.removeAttribute("x-for-tmp");
      if (xFor === null) return;
      template.setAttribute("x-for", xFor);
      const nextSibling = el.nextSibling;
      parent?.removeChild(el);
      template.content.replaceChildren(el);
      parent?.insertBefore(template, nextSibling);
    })
  }
}

export default InjectHandleTemplates;