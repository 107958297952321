import type { APIOpts, ProductDTO } from '@/types';
import { placeOrder } from './orders.global';
import { GlobalScript } from '@/editor/ambient';

export const getAllProducts = (
    workspaceId: string,
    byStatus?: string,
    byName?: string,
    limit?: number,
    skip?: number,
    opts?: APIOpts
  ): Promise<{ count: number, result: ProductDTO[]}> =>
  fetch(`${opts?.apiBase}/workspaces/${workspaceId}/products` +
      ("?" + new URLSearchParams({
        ...(byStatus !== undefined ? {status: byStatus.toString()} : {}),
        ...(byName !== undefined ? {name: byName.toString()} : {}),
        ...(limit !== undefined ? {limit: limit.toString()} : {}),
        ...(skip !== undefined ? {skip: skip.toString()} : {}),
      }))
    ,{
    method: 'GET',
    headers: {
      ...(opts?.authHeader ?? {}),
      'Content-Type': 'application/json',
    }
  })
  .then((x) => x.json());

export const getProduct = (
  workspaceId: string, productId: string,
  opts?: APIOpts
): Promise<ProductDTO> =>
  fetch(`${opts?.apiBase}/workspaces/${workspaceId}/products/${productId}`, {
    method: 'GET',
    headers: {
      ...(opts?.authHeader ?? {}),
      'Content-Type': 'application/json',
    },
  })
  .then((x) => x.json());

export const persistCart = (
  storeId: string, cart: object
) => {
  // Persist the given cart to localStorage
  console.log("Persisting cart!");
  console.log(storeId);
  localStorage.setItem(storeId, JSON.stringify(cart));
}

export const retrieveCart = (
  storeId: string
) => {
  // Retrieve the given cart from localStorage
  return JSON.parse(localStorage.getItem(storeId)!);
}

export const getIntegrationPublicToken = (
  workspaceId: string, name: string,
  opts?: APIOpts
): Promise<{token: string}> =>
  fetch(`${opts?.apiBase}/workspaces/${workspaceId}/integrations/${name}/token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((x) => x.json());

const fillPropertyValues = (
  product: ProductDTO
): Omit<ProductDTO, 'properties'> & { properties: NonNullable<ProductDTO['properties']> } => {
  return {
    ...product,
    properties: product.properties?.map((prop) => ({
      ...prop,
      value: prop.defaultValue
    })) ?? []
  }
}

export const makeProductApi = (opts: APIOpts) => ({ 
  SITE_BUILDER: opts.SITE_BUILDER,
  // This is for convenience. Our Grapes API benefits from having 
  getAllProducts: (...args: Parameters<typeof getAllProducts>) => {
    args[5] = opts; 
    return getAllProducts.apply(null, args).then((x) => ({...x, result: x.result.map((y) => fillPropertyValues(y))}))
  },
  getProduct: (...args: Parameters<typeof getProduct>) => {
    args[2] = opts;
    return getProduct.apply(null, args).then((x) => fillPropertyValues(x))
  },
  persistCart,
  retrieveCart,
  placeOrder: (...args: Parameters<typeof placeOrder>) => {
    args[2] = opts;
    return placeOrder.apply(null, args);
  },
  getIntegrationPublicToken: (...args: Parameters<typeof getIntegrationPublicToken>) => {
    args[2] = opts;
    return getIntegrationPublicToken.apply(null, args);
  }
})


declare global {
  interface Window {
    makeProductApi: typeof makeProductApi
  }
}

const ApiInjector: GlobalScript = (window, document) => {
  window.makeProductApi = makeProductApi;
}

export default ApiInjector;