import type { APIOpts, OrderRequestDTO } from "@/types";

export const placeOrder = (
    workspaceId: string, order: OrderRequestDTO,
    opts?: APIOpts
): Promise<{ clientSecret: string }> => {
  return fetch(`${opts?.apiBase}/workspaces/${workspaceId}/orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order)
  }).then((x) => x.json());
}

export default () => {}