export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('based_link_data', () => ({
      base: '',
      init() {
        const baseElem = document.querySelector('base');
        if (baseElem) {
          this.base = document.baseURI;
        }
      },
      based_link_bind: {
        ':href': "base + $el.dataset.target",
      }
    }));
  })
}