export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('remove_from_cart_data', () => ({
      type: 'button',

      remove_from_cart_bind: {
        '@click'() {
          this.$dispatch('remove-from-cart', {
            productId: this.product._id,
            variantId: this.product.variants?.[0].SKU,
          });
        },
      }
    }))
  });
}