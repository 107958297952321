export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('checkout_form_data', () => ({
      checkout_form_bind: {
        async '@perform-checkout.window'() {
          this.performCheckout()
        }
      },

      generateOrderDto() {
        return {
          sublisting: this.cart.items.map(item => {
            return {
              productId: item.productId,
              variantId: item.variantId,
              price: item.price,
              quantity: item.count,
            }
          }),
          subTotalAmount: this.cart.items.reduce((acc, item) => acc + item.price * item.count, 0),
          provider: 'stripe',
          totalAmount: this.cart.items.reduce((acc, item) => acc + item.price * item.count, 0),
        }
      },

      async init() {
        const orderDto = this.generateOrderDto();

        const stripeApi = window.Stripe(this.$store.products.publicStripeToken);
        this.stripeApi = stripeApi;
        this.stripeElements = stripeApi.elements();
        this.address = {};
        this.email = '';
        this.fullname = '';
        this.phone = '';

        this.$dispatch('stripe-initialized', { stripeApi, stripeElements: this.stripeElements });
        console.log('dispatched stripe-initialized');

        if (orderDto.subTotalAmount === 0) {
          console.log("No items in cart");
          return;
        }

        if (this.$store.products.ProductApi.SITE_BUILDER) {
          console.log('Site builder mode');
          this.clientSecret = '';
          return;
        }

        const result = await this.$store.products.ProductApi.placeOrder(
          this.$store.products.spaceId, orderDto);
        console.log(`Order placed, client secret: ${result}`);
        this.clientSecret = result.clientSecret;
      },

      async performCheckout() {
        console.log("Being called")
        if (this.$store.products.ProductApi.SITE_BUILDER) {
          console.log('Site builder mode');
          return;
        }

        if (this.status === 'processing')
          return;

        if (this.clientSecret === '' || this.clientSecret === undefined || this.clientSecret === null) {
          return;
        }

        this.status = 'processing';
        const { error: stripeError, paymentIntent } = await this.stripeApi.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.stripeElements.getElement('cardNumber'),
            billing_details: {
              name: this.fullname,
              email: this.email,
              phone: this.phone,
              address: {
                line1: this.address.line1,
                line2: this.address.line2,
                city: this.address.city,
                state: this.address.state,
                postal_code: this.address.postal_code,
                country: this.address.country,
              }
            }
          },
        });
        if (stripeError === undefined) {
          this.status = 'done';
          // this.$dispatch('checkout-success', paymentIntent); // <--- MAYBE?s
          this.$dispatch('empty-cart');
        } else {
          this.status = undefined;
        }
      }
    }))
  })
}