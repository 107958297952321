export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('pagination_data', () => ({

      nextBind: {
        ['@click']() {
          this.nextPage()
        }
      },

      prevBind: {
        ['@click']() {
          this.prevPage()
        },
      },

      pageBind: {
        [':class']() {
          return `{ 'pl_li_a flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white': _page != this.page, 'flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white': _page == this.page }`;
        },
        ['@click']() {
          // alert(`${this.page} ${this._page}`)
          if (this._page !== undefined)
            this.setPage(this._page);
        }
      },

      maxVisiblePages: 5,

      get startPage() {
        return Math.max(1, this.page - Math.floor(this.maxVisiblePages / 2));
      },
      get endPage() {
        return Math.min(this.maxPage, this.page + Math.floor(this.maxVisiblePages / 2));
      },
      get visiblePages() {
        const pages = [];
        for (let i = this.startPage; i <= this.endPage; i++) {
          pages.push(i);
        }
        console.log(`Pages are `, pages);
        return pages;
      },

      // Assumes the existene of the following properties
      // this.page
      // this.prevPage()
      // this.nextPage()
      // this.setPage(p)
    }))
  })
}