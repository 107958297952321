import func0 from './src/api/orders.global.ts';
import func1 from './src/api/product.global.ts';
import func2 from './src/editor/plugins/GjsAlpine/handleTemplates.global.ts';
import func3 from './src/editor/plugins/Search/Pagination.global.js';
import func4 from './src/editor/plugins/Search/Search.global.js';
import func5 from './src/editor/plugins/Workspace/addProductsAlpine.global.ts';
import func6 from './src/editor/plugins/Workspace/BaesdLink.global.js';
import func7 from './src/editor/plugins/Workspace/Store/AddToCart.global.ts';
import func8 from './src/editor/plugins/Workspace/Store/Cart.global.ts';
import func9 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutAddress.global.js';
import func10 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutCardComponent.global.js';
import func11 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutCardCVCComponent.global.js';
import func12 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutCardExpiryComponent.global.js';
import func13 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutCardNumberComponent.global.js';
import func14 from './src/editor/plugins/Workspace/Store/Checkout/CheckoutFormComponent.global.js';
import func15 from './src/editor/plugins/Workspace/Store/Checkout/PerformCheckout.global.js';
import func16 from './src/editor/plugins/Workspace/Store/ProductLink.global.js';
import func17 from './src/editor/plugins/Workspace/Store/RemoveFromCart.global.js';
import func18 from './src/editor/plugins/Workspace/Store/Store.global.js';


const modules = [
{ func: func0, priority: func0.priority || 0 },
{ func: func1, priority: func1.priority || 0 },
{ func: func2, priority: func2.priority || 0 },
{ func: func3, priority: func3.priority || 0 },
{ func: func4, priority: func4.priority || 0 },
{ func: func5, priority: func5.priority || 0 },
{ func: func6, priority: func6.priority || 0 },
{ func: func7, priority: func7.priority || 0 },
{ func: func8, priority: func8.priority || 0 },
{ func: func9, priority: func9.priority || 0 },
{ func: func10, priority: func10.priority || 0 },
{ func: func11, priority: func11.priority || 0 },
{ func: func12, priority: func12.priority || 0 },
{ func: func13, priority: func13.priority || 0 },
{ func: func14, priority: func14.priority || 0 },
{ func: func15, priority: func15.priority || 0 },
{ func: func16, priority: func16.priority || 0 },
{ func: func17, priority: func17.priority || 0 },
{ func: func18, priority: func18.priority || 0 },

];

modules.sort((a, b) => b.priority - a.priority);

modules.forEach(({ func }) => {
  func(window, document);
});
