export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('search', () => ({
      search_text: '',
      search_button_bind: {
        '@click'() {
          this.query(this.search_text)
        }
      },
    }))
  })
}