export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('perform_checkout_data', () => ({
      type: 'button',

      perform_checkout_bind: {
        '@click'() {
          this.$dispatch('perform-checkout');
        },
        ':disabled': "(status === 'processing' || status === 'done')",
        'x-html': "(status === 'processing' || status==='done') ? 'Processing...' : 'Perform checkout'",
      }
    }))
  })
}