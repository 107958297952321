export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('checkout_card_data', () => ({
      async init() {
        if (this.stripeElements !== undefined) {
          this.initElement(this.stripeElements);
        }
      },

      mountStripe() {
        this.stripeElement?.mount(this.$el);
      },

      initElement(stripeElements) {
        this.stripeElement = stripeElements.create("card");
        this.mountStripe();
      },

      'checkout_card_bind': {
        '@stripe-initialized.window'() {
          const { stripeApi, stripeElements } = this.$event.detail;
          this.initElement(stripeElements)
        }
      }
    }))
  })
}