import type { GlobalScript } from '@/editor/ambient';

export default <GlobalScript> ((window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('add_to_cart_data', () => ({
      type: 'button',

      add_to_cart_bind: {
        '@click'() {
          const currentVariant = (this as any).currentVariant;
          if (currentVariant === undefined || currentVariant === null) {
            alert('Selected variant is not available');
            return;
          }

          const count = (this as any).product.count ?? 1;;

          if ((currentVariant.stock ?? 0) < count) {
            alert('Not enough stock');
            return;
          }

          this.$dispatch('add-to-cart', {
            productId: (this as any).product._id,
            variantId: currentVariant.SKU,
            price: currentVariant.price,
            count: count
          }
          );
        },
      }
    }))
  })
})