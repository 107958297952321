export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('store_data', () => ({
      products: [],
      cart: {
        items: []
      },
      count: 0,
      byName: '',
      perPage: 10,
      page: 1,

      async init() {
        const persistedCart = this.$store.products?.ProductApi.retrieveCart(this.$store.products.spaceId);
        if (persistedCart !== null)
          this.cart = persistedCart;

        // this.fetch();
      },

      async query(byName, page, perPage) {
        this.byName = byName;
        this.page = page;
        this.perPage = perPage;
      },

      async fetch() {
        const spaceId = this.$store.products.spaceId;
        const response = await this.$store.products.ProductApi.getAllProducts(
          spaceId,
          undefined,
          // Query params
          this.byName,
          this.perPage,
          (this.page - 1) * this.perPage
        )
        this.products = response.result;
        this.count = response.count;
      },

      /// Pagination
      clamp(val, min, max) {
        return Math.min(Math.max(val, min), max);
      },

      get maxPage() {
        return Math.ceil(this.count / this.perPage);
      },

      get atFirstPage() {
        return this.page === 1;
      },

      get atLastPage() {
        return this.page === this.maxPage;
      },

      async setPage(page) {
        const oldPage = this.page;
        const newPage = this.clamp(page, 1, this.maxPage);
        if (oldPage !== newPage)
          this.page = this.clamp(page, 1, this.maxPage);
      },

      async nextPage() {
        if (this.page < this.maxPage)
          this.setPage(this.page + 1);
      },

      async prevPage() {
        if (this.page > 1)
          this.setPage(this.page - 1);
      },
      ///

      __total() {
        let tot = 0;
        for (let item of this.cart.items) {
          tot += item.count * item.price;
        }

        return tot;
      },

      __products() {
        return this.products
      }
    }))

    window.Alpine.data('product_data', (isTemplate) => ({
      // init() {
      //   // TODO:
      //   // Here we include the case when there is a "highlighted" product
      //   // either based on a given attribute, or via some higher sign (the surrounding store)
      // },
      isTemplate: isTemplate,
      _product: null,
      get product() {
        if (this._product !== null)
          return this._product;
        return this.$root._x_dataStack?.[1].product;
      },

      get currentVariant() {
        // Match property values with respective variant
        const product = this.product;
        if (product === undefined || product === null)
          return null;

        const variant = product.variants.find(v => {
          for (let prop of v.propertyValues ?? []) {
            const property = product.properties.find(p => p.name === prop.name);
            if (!property.unique || property.productWide)
              continue;
            const propValue = property.value;
            if (propValue !== prop.value)
              return false;
          }
          return true;
        });

        // return variant ?? product.variants[0];
        return variant ?? null; // Return null if no variant is found, so we can display a message or smth
      },

      async init() {
        if ((isTemplate ?? true) === false) {
          let productId = this.$el.dataset.productid;

          if (productId === undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            productId = urlParams.get('id');
          }

          if (productId !== undefined && productId !== null) {
            const apiResult = await this.$store.products.ProductApi.getProduct(
              this.$store.products.spaceId,
              productId
            );

            this._product = {
              ...apiResult,
              properties: [ //Concatenate properties and global properties, for easier use by the frontend
                ...apiResult.properties,
                ...(apiResult.globalProperties??[])
              ]
            }
          }
        }
      },

      __total() {
        return this.product.count * this.product.price;
      }
    }))

    window.Alpine.data('property_data', (defaultProp) => ({
      // property: null,
      set value(val) {
        const propName = this.$root.dataset.propname;
        if (propName === undefined)
          return;
        const property = this.$root._x_dataStack[1].product.properties.find(p => p.name === propName);
        if (property === undefined)
          return;
        property.value = val;
      },
      get value() {
        return this.property?.value ?? null;
      },
      init() {
        this.__tick = ((this.__tick ?? 0) + 1) % 1000;
      },
      _property: undefined,
      get property() {
        const _ = this.__tick;
        const product = this.product;
        console.warn("Fetching property() for ", product)
        const propName = this.$root.dataset.propname;
        if (propName === undefined || propName === '')
          return;
        if (this._property?.[1] === null && this._property[0] === propName)
          return null;
        if (this._property?.[0] === propName)
          return this._property[1];

        if (product === undefined)
          return null;

        const propMemo = this._property = [propName, product?.properties?.find(p => p.name === propName) ?? null];
        const result = propMemo[1];
        console.warn("Fetched property()", propMemo)
        return result;
      }
    }))

  })
}