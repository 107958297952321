export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('product_link_data', () => ({
      base: '',
      init() {
        const baseElem = document.querySelector('base');
        if (baseElem) {
          this.base = document.baseURI;
        }
      },
      get productId() {
        if (typeof this.product !== 'undefined' && this.product !== null) {
          return this.product._id;
        }
      },
      product_link_bind: {
        ':href': "base + $el.dataset.target + '?id=' + productId",
      }
    }));
  })
}