import type { GlobalScript } from '@/editor/ambient';

export default <GlobalScript>((window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('cart_data', () => ({
      // No need to initialize this, since it
      // exists in the supposedly surrounding scope (the Store)
      // cart: [],
      hydratedProducts: [] as any[],

      cart_bind: {
        '@add-to-cart.window'() {
          // ADD TO CART
          const toAdd = (this as any).$event.detail;

          console.log(`Adding ${toAdd.productId} to cart`);
          if ((this as any).cart.items.some((item: any) => (
            item.productId == toAdd.productId && item.variantId == toAdd.variantId
          ))) {
            console.log("Already in cart!");
          } else {
            ((this as any).cart as any).items.push(
              {
                productId: toAdd.productId,
                variantId: toAdd.variantId,
                price: toAdd.price,
                count: toAdd.count
              }
            );

            // this.hydrateProducts(this.cart.items);
            // this.cart.items.push({ ...product, variantId: product.variants[0].SKU, price: product.variants[0].price, "count": 1 })
          }
        },

        '@empty-cart.window'() {
          // EMPTY CART
          (this as any).cart.items = [];
        },

        '@remove-from-cart.window'() {
          // REMOVE FROM CART
          const { productId, variantId } = (this as any).$event.detail;
          (this as any).cart.items = (this as any).cart.items.filter((item: any) => item.productId != productId || item.variantId != variantId);
        },
      },

      async init() { },

      async hydrateProducts(items: any[]) {
        // console.log("Hydrating products");
        let anyNew = false;
        const len = this.hydrateProducts?.length ?? 0;
        const result = await Promise.all(items.map(async item => {
          const found = this.hydratedProducts?.find(
            (p: any) => p.productId === item.productId && p.variantId === item.variantId
          );

          if (found)
            return found;

          anyNew = true;
          return {
            ...await (this.$store.products as any)?.ProductApi.getProduct((this.$store.products as any).spaceId, item.productId),
            ...item
          }
        }));
        if (anyNew || len > result.length) {
          console.log(result);
          this.hydratedProducts = result;
        }
        // console.log("Hydrated products");
      },

      __products() {
        return this.hydratedProducts
      }
    }))
  })
});