export default (window, document) => {
  document.addEventListener('alpine:init', () => {
    window.Alpine.data('checkout_address_data', () => ({
      async init() {
        if (this.stripeElements !== undefined) {
          this.initElement(this.stripeElements);
        }
      },

      mountStripe() {
        this.stripeElement?.mount(this.$el);
      },

      initElement(stripeElements) {
        this.stripeElement = stripeElements.create("address", { mode: "shipping" });
        this.stripeElement.on('change', (event) => {
          const address = event.value;
          this.address = address;
        })
        this.mountStripe();
      },

      'checkout_address_bind': {
        '@stripe-initialized.window'() {
          const { stripeApi, stripeElements } = this.$event.detail;
        }
      }
    }))
  })
}