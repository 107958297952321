export default (window, document) => {
  document.addEventListener('alpine:init', () => {

    window.Alpine.data('checkout_cardcvc_data', () => ({
      async init() {
        if (this.stripeElements !== undefined) {
          this.initElement(this.stripeElements);
        }
      },

      mountStripe() {
        this.stripeElement?.mount(this.$el);
      },

      initElement(stripeElements) {
        this.stripeElement = stripeElements.create("cardCvc");
        this.mountStripe();
      },


      'checkout_cardcvc_bind': {
        '@stripe-initialized.window'() {
          const { stripeApi, stripeElements } = this.$event.detail;
          this.initElement(stripeElements)
        }
      }

    }))
  })
}